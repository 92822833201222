<!-- 菜单项组件 RightMenuItemComponet.vue -->

<template>
  <div class='flex right-menu-item align-item-center pointer' @mouseover='handleOver' @mouseleave='handleLeave'
       :style='{ backgroundColor: bdColor, color: colorStr}'>
    <i :class='icon'></i>
    <div style='width: 70%;' class='text-overflow-hidden margin-left-small'>{{ name }}</div>
  </div>
</template>

<script>
export default {
  name: 'RightMenuItemComponent',
  data () {
    return {
      bdColor: 'white',
      colorStr: 'var(--color-main-font)'
    }
  },
  props: ['icon', 'name'],
  methods: {
    handleOver () {
      this.bdColor = '#ecf5ff'
      this.colorStr = 'var(--color-main)'
    },
    handleLeave () {
      this.bdColor = 'white'
      this.colorStr = 'var(--color-main-font)'
    }
  }
}
</script>

<style lang='scss' scoped>
.right-menu-item {
  padding: 3px 6px 3px 6px;
  width: calc(100% - 12px);
  height: 40px;
  text-align: start;
}

i {
  font-size: 25px;
  color: var(--color-main);
}
</style>
