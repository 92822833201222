<template>
  <div class="app-main">
    <div class="mainbody">
      <router-view v-slot="{ Component, route }">
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <component :is="Component" :key="route.path" />
          </keep-alive>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { isTags } from '@/utils/tags'
import { generateTitle, watchSwitchLang } from '@/utils/i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()

/**
 * 生成 title
 */
const getTitle = route => {
  let title = ''
  if (!route.meta) {
    // 处理无 meta 的路由
    const pathArr = route.path.split('/')
    title = pathArr[pathArr.length - 1]
  } else {
    title = generateTitle(route.meta.title)
  }
  return title
}

/**
 * 监听路由变化
 */
const store = useStore()
watch(
  route,
  (to, from) => {
    if (!isTags(to.path)) return
    const { fullPath, meta, name, params, path, query } = to
    store.commit('app/addTagsViewList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    })
  },
  {
    immediate: true
  }
)

/**
 * 国际化 tags
 */
watchSwitchLang(() => {
  store.getters.tagsViewList.forEach((route, index) => {
    store.commit('app/changeTagsView', {
      index,
      tag: {
        ...route,
        title: getTitle(route)
      }
    })
  })
})
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px - 43px - 200px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 60px 0px 0px 0px;
  box-sizing: border-box;
  .toolbar{
      height:$mainLayoutToolbarHeight;
      width:100%;
      //position: fixed;
      //z-index: 1;
      line-height:$mainLayoutToolbarHeight;
      border-bottom: $border-base;
      //box-shadow: 0px 1px 4px rgb(0 21 41 / 8%);
      background-color:$background-color-primary;
      //background-color:rgb(94, 55, 55);
      overflow: hidden;
      .toolbar__left{
        padding-left: $contentPadding;
        width: calc(70% - $contentPadding);
        float:left;
        overflow: hidden;
      }
      .toolbar__right{
        padding-right: $contentPadding;
        width: calc(30% - $contentPadding);
        float:left;
        text-align:right;
        overflow: hidden;
      }
    }
}
</style>
