<template>
  <div class="logo-container">
    <!-- <svg-icon icon="logo2" style="" /> -->
<!--    <el-image style="width:37px;height:37px;vertical-align:middle;" :src="logoUrl" />-->
    <el-image style="width:120px;vertical-align:middle;" @click="appMouseDown" :src="logoUrl" />
<!--    <span class="title">{{ testName }}</span>-->
  </div>
</template>

<script setup>
import { getTestCfgByCode } from '@/api/testcfg'
import store from '@/store'
import { ref } from 'vue'
import { watchSwitchLang } from '@/utils/i18n'
import { useRoute } from 'vue-router'

const route = useRoute()
const testName = ref('')
const logoUrl = require('../../../assets/images/ic_logo.png')
const doGetTestCfgByCode = async () => {
  const ret = await getTestCfgByCode(store.getters.userInfo.testCode)
  testName.value = ret.data.name
}
// 1./home 首页不检查用户 2./tests/index 检查
// if ('/home'.indexOf(route.path) === -1) {
//   doGetTestCfgByCode()
//   watchSwitchLang(doGetTestCfgByCode)
// }
const appMouseDown = () => {
  store.commit('changeShowRightMenu', false)
}
</script>

<style lang="scss" scoped>
.logo-container{
  left: 10px;
  top: 9px;
  display: inline-block;
  position: fixed;

  .svg-icon {
    width:37px;
    height:30px;
    vertical-align:middle;
    // transform:rotate(310deg)
  }
  .title{
    font-size: 1.1rem;
    display: inline-block;
    margin-left:10px;
  }
}
</style>
