<template>
  <div class="navbar">
    <AsideComponent />
    <logo />
<!--    <logoCenter />-->
    <!-- <el-button class="newbtn">新增邀请</el-button> -->
    <div class="right-menu" >
      <lang-select v-if='false' class="right-menu-item hover-effect" />
      <screenfull v-if='false' class="right-menu-item hover-effect"/>
      <!-- 头像 -->
      <el-dropdown v-if='isUserInfo' class="avatar-container" trigger="">
        <div class="avatar-wrapper">
          <el-avatar
            shape="circle"
            :size="20"
            :src="$store.getters.userInfo.avatar"
            style="position: relative;left: 10px;top: 6px;"
          ></el-avatar>
          <i class="el-icon-s-tools">{{ name }}</i>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="user-dropdown">
            <!-- <router-link to="/">
              <el-dropdown-item> {{ $t('msg.navBar.home') }} </el-dropdown-item>
            </router-link> -->
            <el-dropdown-item divided @click="logout">
              {{ $t('msg.navBar.logout') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-image style="width:28px;vertical-align:middle;position: relative;top: 16px;margin-left: 5px" @click="appMouseDown" :src="navigationUrl" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Screenfull from '@/components/appMobile/Screenfull'
import Logo from '@/components/appMobile/Logo'
import LangSelect from '@/components/appMobile/LangSelect'
import LogoCenter from '@/components/appMobile/LogoCenter'
import AsideComponent from '@/views/home-mobile/AsideComponent'
const store = useStore()
const name = ref(store.getters.userInfo.name)
const navigationUrl = require('../../../assets/images/navigation.png')
const logout = () => {
  store.dispatch('user/logout')
}
const isUserInfo = ref(false)
if (name.value !== null && name.value !== undefined && name.value !== '') {
  isUserInfo.value = true
}
const appMouseDown = () => {
  store.commit('changeShowRightMenu', true)
}
</script>

<style lang="scss" scoped>
.navbar {
  height: $navbarHeight;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background: $background-color-primary;
  //box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: $border-base;
  text-align: center;
  z-index: 1000;
  .newbtn{
    margin-top:9px;
    margin-left:25px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    align-items: center;
    display: inline-block;
    padding-right: 16px;
    position: fixed;
    /* top: -160px; */
    /* left: 300px; */
    right: 0;
    top: 0;

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 5px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
        line-height: 60px;

        //&:hover {
        //  background: rgba(0, 0, 0, 0.025);
        //}
      }
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }

  .avatar-container{
    line-height:$navbarHeight;

    .avatar-wrapper {
      margin-top:0px !important;
    }
  }
}
</style>
