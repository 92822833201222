export default {
  state: {
    showRightMenu: false
  },
  mutations: {
    changeShowRightMenu (state, val) {
      state.showRightMenu = val
      // state.showRightMenu = !state.showRightMenu
    }
  }
}
