<template>
  <div class="logo-container2">
    <!-- <svg-icon icon="logo2" style="" /> -->
    <el-image style="width:140px;vertical-align:middle;" :src="logoUrl" />
<!--    <el-image style="width:37px;height:37px;vertical-align:middle;" :src="navigationUrl" />-->
<!--    <span class="title">{{ testName }}</span>-->
  </div>
</template>

<script setup>
const logoUrl = require('../../assets/images/ic_logo.png')
</script>

<style lang="scss" scoped>
.logo-container2{
  margin-top: 7px;
  //position: relative;
  //top: -20px;
  //display: inline-block;
  //font-size: 14px;
  //line-height: 50px;
  //margin-left: 8px;

}
</style>
