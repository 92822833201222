<!-- 侧边栏 AsideComponent.vue -->

<template>
  <div class='rightMenuDiv' style="width:174px; height:100%;position: fixed;top: 0;opacity: 1;z-index: 1000;right: 0" v-if="showRightMenu">
    <el-image v-show="showRightMenu" @click='CloseRightMenu'  :src='rightMenuCloseImg' class='rightClose'></el-image>
    <el-menu default-active="2"
             active-text-color="#6E38F7"
             style="height: 100%;width: 100%!important;border-right: none;"
             :collapse="isCollapse"
             :collapse-transition="false">
      <!-- 侧边导航栏，自己的代码 -->
      <!-- 注意需要在菜单项上面绑定右击事件的拦截函数，使用div绑定 -->
      <div @contextmenu.prevent="rightClick(index, indexMeasure, $event)">
        <el-menu-item class='homeRightMenuItem homeRightMenu' @mouseenter="hovering = true" @mouseleave="hovering = false" @click='goHomePage' style='padding: 0;border-radius: 0;justify-content: center;height: 60px;display: block' index="file1">
          <template #title>
            <i class="el-icon-document"></i>
            <span>首页</span>
          </template>
        </el-menu-item>
        <el-menu-item class='homeRightMenuItem homeRightMenu' @click='EnterpriseLogin' style='padding: 0;border-radius: 0;justify-content: center;height: 60px;display: block' index="file2">
          <template #title>
            <i class="el-icon-document"></i>
            <span>企业登陆</span>
          </template>
        </el-menu-item>
        <el-menu-item class='homeRightMenuItem homeRightMenu' @click='goPersonTests' style='padding: 0;border-radius: 0;justify-content: center;height: 60px;display: block' index="file3">
          <template #title>
            <i class="el-icon-document"></i>
            <span>个人测评</span>
          </template>
        </el-menu-item>
        <el-menu-item class='homeRightMenuItem homeRightMenu' @click='viewReport' style='padding: 0;border-radius: 0;justify-content: center;height: 60px;display: block' index="file4">
          <template #title>
            <i class="el-icon-document"></i>
            <span>查看报告</span>
          </template>
        </el-menu-item>
        <el-menu-item v-if="isLogoutShow == '' || isLogoutShow == undefined ? false : true" class='homeRightMenuItem homeRightMenu' @click='logout' style='padding: 0;border-radius: 0;justify-content: center;height: 60px;display: block' index="file5">
          <template #title>
            <i class="el-icon-document"></i>
            <span>退出登陆</span>
          </template>
        </el-menu-item>
      </div>
    </el-menu>
    <div class="right-menu border-radius-little box-shadow" :style="{top: topNumber+'px', left: leftNumber+'px'}">
      <right-menu-component></right-menu-component>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapState, useStore } from 'vuex'
import RightMenuComponent from './RightMenuComponent'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { JumpConfig } from '@/utils/config'
const store = useStore()
const hovering = ref(false)
const rightMenuCloseImg = require('../../assets/images/home/right_menu_close.png')
export default {
  name: 'AsideComponent',
  data () {
    return {
      topNumber: 0,
      leftNumber: 0,
      isCollapse: false,
      router: useRouter(),
      store: useStore(),
      rightMenuCloseImg: rightMenuCloseImg,
      JumpConfig: JumpConfig,
      isLogoutShow: useStore().getters.userInfo.name,
      userInfo: useStore().getters.userInfo
    }
  },

  components: {
    RightMenuComponent
  },

  computed: {
    ...mapState({
      showRightMenu: (state) => state.menuState.showRightMenu
    })
  },

  methods: {
    rightClick (index, indexMeasure, e) {
      this.$store.commit('changeShowRightMenu', true)
      this.topNumber = e.pageY
      this.leftNumber = e.pageX
    },
    viewReport () {
      window.open(this.JumpConfig.ViewReportUrl, '_blank')
      // this.CloseRightMenu()
    },
    EnterpriseLogin () {
      window.open(this.JumpConfig.EnterpriseLoginUrl, '_blank')
      // this.CloseRightMenu()
    },
    logout () {
      this.store.dispatch('user/logout')
      // this.CloseRightMenu()
    },
    goHomePage () {
      // window.location.href = this.JumpConfig.HomeUrl
      this.router.push('/')
      this.CloseRightMenu()
    },
    goPersonTests () {
      // window.location.href = this.JumpConfig.LoginUrl
      window.open(this.JumpConfig.LoginUrl, '_blank')
      // this.CloseRightMenu()
    },
    CloseRightMenu () {
      this.store.commit('changeShowRightMenu', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.rightMenuDiv .el-menu {
  //width: 100%;
  //border-right: none;
  //text-align: start;
  //justify-content: center;
}
.right-menu {
  width: 130px;
  position: fixed;
  z-index: 1000;
  background-color: white;
}

i {
  font-size: 25px;
  color: var(--color-main);
}
.homeRightMenu:hover {
  background: #f5f5f5!important;
}
.homeRightMenuItem{
  border-bottom: 1px solid #eeeff1;
}
.rightClose{
  width: 13px;
  position: inherit;
  right: 146px;
  top: 15px;
  z-index: 1100;
}
</style>
