import { TIME_STAMP, TOKEN_TIMEOUT_VALUE } from '@/constant'
import { setItem, getItem } from '@/utils/storage'
/**
 * 获取时间戳
 */
export function getTimeStamp () {
  return getItem(TIME_STAMP)
}
/**
 * 设置时间戳
 */
export function setTimeStamp () {
  setItem(TIME_STAMP, Date.now())
}
/**
 * 是否超时
 */
export function isCheckTimeout () {
  // 当前时间戳
  var currentTime = Date.now()
  // 缓存时间戳
  var timeStamp = getTimeStamp()
  return currentTime - timeStamp > TOKEN_TIMEOUT_VALUE
}

export function getUtlParam (url) {
  url = url == null ? url : window.location.href.substring(0, window.location.href.length - 2)
  var str = url.substring(url.indexOf('?') + 1)
  var arrs = str.split('&')
  var result = {}
  arrs.forEach(item => {
    var keyArr = item.split('=')
    var name = keyArr[0]
    var value = keyArr[1]
    result[name] = value
  })
  return result
}
