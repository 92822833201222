import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { isCheckTimeout } from '@/utils/auth'
// import { useStore } from 'vuex'
console.log(process.env)
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60 * 5
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在这个位置需要统一的去注入token
    if (store.getters.token) {
      if (isCheckTimeout()) {
        // 登出操作
        store.dispatch('user/logout')
        store.commit('app/setLoading', false)
        return Promise.reject(new Error('token 失效'))
      }
      // 如果token存在 注入token
      config.headers.Authorization = `Bearer ${store.getters.token}`
    }

    // 配置接口国际化
    config.headers['Accept-Language'] = store.getters.language
    return config // 必须返回配置
  },
  error => {
    store.commit('app/setLoading', false)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const { code, error, data } = response.data
    console.log('response.data', data, 'code:', code)
    //   要根据success的成功与否决定下面的操作
    if (code === 200) {
      return response.data
    } else {
      // 业务错误
      store.commit('app/setLoading', false)
      ElMessage.error(error) // 提示错误消息
      return Promise.reject(new Error(error))
    }
  },
  error => {
    // 处理 token 超时问题
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === 401
    ) {
      // token超时
      store.dispatch('user/logout')
    }
    store.commit('app/setLoading', false)
    // ElMessage.error(error.message) // 提示错误信息
    return Promise.reject(error)
  }
)

export default service
