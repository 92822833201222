import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import installElementPlus from './plugins/element'
import '@/styles/index.scss'
import 'normalize.css/normalize.css'
// 导入 svgIcon
import installIcons from '@/icons'
// filter
import installFilter from '@/filters'
import './permission'
import i18n from '@/i18n'
import smoothscroll from 'smoothscroll-polyfill' // smoothscroll-polyfill插件 滚动条适应safari 谷歌等浏览器
smoothscroll.polyfill()

const app = createApp(App)
installElementPlus(app)
installIcons(app)
installFilter(app)
app.use(store).use(router).use(i18n).mount('#app')
