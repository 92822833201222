import request from '@/utils/request'
// import { urlEncode } from '@/utils/urls'

export const getTestCfgs = () => {
  return request({
    url: '/api/v1/ts/test-cfgs',
    method: 'GET'
  })
}

export const getTestCfgsByAppKey = (appKey) => {
  return request({
    url: '/api/v1/ts/test-cfgs-by-appkey?appKey=' + appKey,
    method: 'GET'
  })
}

export const getTestCfgByCode = (testCode) => {
  return request({
    url: '/api/v1/ts/test-cfgs/' + testCode,
    method: 'GET'
  })
}
