import { login, getUserInfo } from '@/api/sys'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import { setTimeStamp } from '@/utils/auth'
import router, { resetRouter } from '@/router'

// import { shallowRef } from 'vue'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {}
  }),
  mutations: {
    setToken (state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo (state, userInfo) {
      console.log('setUserInfo:', userInfo)
      state.userInfo = userInfo
    }
  },
  actions: {
    login (context, userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({
          username,
          password: password
        }).then(data => {
          this.commit('user/setToken', data.data)
          setTimeStamp()
          resolve(data.data)
        }).catch(error => reject(error))
      })
    },
    async getUserInfo (context) {
      const userInfo = await getUserInfo()
      this.commit('user/setUserInfo', userInfo.data)
      return userInfo.data
    },
    logout () {
      resetRouter()
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      removeAllItem()
      router.push('/')
    }
  }
}
