<!-- 菜单组件 RightMenuComponent.vue -->

<template>
  <div style='width: 100%;' class='border-radius-little'>
    <div style='width: 100%;' v-for='(item, index) in rightMenuList' :key='item.name'>
      <right-menu-item
        :class='index == rightMenuList.length - 1 ? "border-radius-bottom-little" : index == 0 ? "border-radius-top-little bd-bottom" : "bd-bottom"'
        :icon='item.icon' :name='item.name'></right-menu-item>
    </div>
  </div>
</template>

<script>
import RightMenuItem from './RightMenuItemComponet'
export default {
  name: 'RightMenuComponent',
  data () {
    return {
      rightMenuList: []
      // rightMenuList: [{
      //   icon: 'el-icon-document',
      //   name: 'new File',
      //   id: 'create'
      // }, {
      //   icon: 'el-icon-refresh-right',
      //   name: 'refresh',
      //   id: 'refresh'
      // }, {
      //   icon: 'el-icon-delete',
      //   name: 'delete',
      //   id: 'delete'
      // }]
    }
  },
  components: {
    RightMenuItem
  }
}
</script>

<style lang='scss' scoped>
</style>
