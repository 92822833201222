<template>
  <div class="navbar">
        <AsideComponent />
        <logo />
        <logoCenter />
        <!-- <el-button class="newbtn">新增邀请</el-button> -->
        <div class="right-menu" v-if='isUserInfo'>
    <!--      <lang-select class="right-menu-item hover-effect" />-->
          <screenfull class="right-menu-item hover-effect"/>
          <!-- 头像 -->
          <el-dropdown class="avatar-container" trigger="">
            <div class="avatar-wrapper">
              <el-avatar
                shape="circle"
                :size="30"
                :src="$store.getters.userInfo.avatar"
                style="position: relative;left: 5px;top: 10px;"
              ></el-avatar>
              <i class="el-icon-s-tools">{{ name }}</i>
            </div>
            <template #dropdown>
              <el-dropdown-menu class="user-dropdown">
                <!-- <router-link to="/">
                  <el-dropdown-item> {{ $t('msg.navBar.home') }} </el-dropdown-item>
                </router-link> -->
                <el-dropdown-item divided @click="logout">
                  {{ $t('msg.navBar.logout') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
<!--    <logo />-->
<!--    <logoCenter />-->
<!--    &lt;!&ndash; <el-button class="newbtn">新增邀请</el-button> &ndash;&gt;-->
<!--    <div class="right-menu">-->
<!--&lt;!&ndash;      <lang-select class="right-menu-item hover-effect" />&ndash;&gt;-->
<!--      <screenfull class="right-menu-item hover-effect"/>-->
<!--      &lt;!&ndash; 头像 &ndash;&gt;-->
<!--      <el-dropdown class="avatar-container" trigger="click">-->
<!--        <div class="avatar-wrapper">-->
<!--          <el-avatar-->
<!--            shape="circle"-->
<!--            :size="30"-->
<!--            :src="$store.getters.userInfo.avatar"-->
<!--            style="position: relative;left: 5px;top: 10px;"-->
<!--          ></el-avatar>-->
<!--          <i class="el-icon-s-tools">{{ name }}</i>-->
<!--        </div>-->
<!--        <template #dropdown>-->
<!--          <el-dropdown-menu class="user-dropdown">-->
<!--            &lt;!&ndash; <router-link to="/">-->
<!--              <el-dropdown-item> {{ $t('msg.navBar.home') }} </el-dropdown-item>-->
<!--            </router-link> &ndash;&gt;-->
<!--            <el-dropdown-item divided @click="logout">-->
<!--              {{ $t('msg.navBar.logout') }}-->
<!--            </el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </template>-->
<!--      </el-dropdown>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Screenfull from '@/components/Screenfull'
import Logo from '@/components/Logo'
import LangSelect from '@/components/LangSelect'
import LogoCenter from '@/components/LogoCenter'
import AsideComponent from '@/views/home/AsideComponent'
const store = useStore()
const name = ref(store.getters.userInfo.name)
// localStorage.setItem('yq_client_token', 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjUwOCwicm9sZUlkcyI6W10sImNsaWVudElwIjoiOjoxIiwiYXBwS2V5Ijoid2VpbGFpIiwidG9rZW4iOm51bGwsImV4cCI6MTcxMDgzNjY3N30.8IHE_ii7IKRvwSwL1HmiMJ0gLChpToelggnxMozEkSQ')
// localStorage.setItem('timeStamp', '1710750277497')

const logout = () => {
  store.dispatch('user/logout')
}
const isUserInfo = ref(false)
if (name.value !== null && name.value !== undefined && name.value !== '') {
  isUserInfo.value = true
}
</script>

<style lang="scss" scoped>
.navbar {
  //height: $navbarHeight;
  //overflow: hidden;
  //position: relative;
  //background: $background-color-primary;
  ////box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  //border-bottom: $border-base;
  //text-align: center;
  height: $navbarHeight;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background: $background-color-primary;
  //box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: $border-base;
  text-align: center;
  z-index: 1000;
  .newbtn{
    margin-top:9px;
    margin-left:25px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    align-items: center;
    display: inline-block;
    padding-right: 16px;
    position: fixed;
    /* top: -160px; */
    /* left: 300px; */
    right: 0;
    top: 0;

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 5px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
        line-height: 60px;

        //&:hover {
        //  background: rgba(0, 0, 0, 0.025);
        //}
      }
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }

  .avatar-container{
    line-height:$navbarHeight;

    .avatar-wrapper {
      margin-top:0px !important;
    }
  }
}
</style>
