export const JumpConfig = {
  // LoginUrl: 'http://192.168.0.107:8080/test',
  // EnterpriseLoginUrl: 'http://192.168.0.107:8081/etm',
  // ViewReportUrl: 'http://192.168.0.107:8081/etm/#/report'

  // LoginUrl: 'http://192.168.1.6:8080/test',
  // EnterpriseLoginUrl: 'http://192.168.1.6:8081/etm',
  // ViewReportUrl: 'http://192.168.1.6:8081/etm/#/report'
  LoginUrl: process.env.VUE_APP_LoginUrl,
  EnterpriseLoginUrl: process.env.VUE_APP_EnterpriseLoginUrl,
  ViewReportUrl: process.env.VUE_APP_ViewReportUrl
}
