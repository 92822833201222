import request from '@/utils/request'

/**
 * 登录
 */
export const login = data => {
  const reqLogin = {}
  reqLogin.username = data.username
  reqLogin.password = data.password
  reqLogin.appKey = 'AIDOCS'
  reqLogin.loginType = data.username.indexOf('_') !== -1 ? 'YQ_TEST_LOGIN' : 'YQ_INTERVIEW_LOGIN'
  console.log('reqLogin', reqLogin)
  return request({
    url: '/api/v1/users/login',
    method: 'POST',
    data: reqLogin
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/api/v1/personals/profile'
  })
}

export const getInterviewCodeInfo = (interviewCode) => {
  return request({
    url: 'api/v1/codes/' + interviewCode,
    method: 'GET'
  })
}
